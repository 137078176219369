
<template>
<form autocomplete="off" class="">
  <is-loading v-if="isLoading" :box="true" />
  <div class="row g-3">
    <div v-if="!editMode" class="col-sm-6">
      <label> Select medicine type </label>
      <multiselect 
      mode="single"
      searchable
      valueProp="id"
      trackBy="title" label="title"
      @change="medicineTypeChange($event)"
      :options="medicineTypes" 
        placeholder="--select--"></multiselect>
    </div>
    <div v-if="!editMode" class="col-sm-6">
      <label> Medicine </label>
      <multiselect 
        mode="single"
        searchable
        valueProp="id"
        trackBy="name" label="name"
        :options="medicineOptions" 
        v-model="data.medicine_id" placeholder="--select medicine --">
        <template v-slot:option="{ option }">
          <div class="">
            <h6 class="mb-0">{{ option.name }} - {{ option.type.title }}</h6>
            <span>{{ option.description }}</span>
          </div>
        </template>
      </multiselect>
    </div> 
    <div v-if="selected" class="col-12">
      <div class="alert mb-0" :class="selected.available_quantity_boolean ? 'alert-primary' :'alert-danger'">
        <p class="mb-0"><strong>Medicine: {{ selected.name }} - {{ selected.type.title }}</strong></p>
        <p class="mb-0">Description: {{ selected.description }}</p>
        <h6 v-if="!selected.available_quantity_boolean" class="mt-2"> This medicine is not available in the central point </h6>
      </div>
    </div>
    <div class="col-sm-6">
      <label class="form-label" for="quantity">Quantity <span class="text-lowercase" v-if="selected">(per {{ selected.minimum_unit }})</span></label>
      <input id="quantity" autocomplete="false" type="number" class="form-control" v-model="data.quantity"
      placeholder="Quantity" name="quantity" :class="{ 'is-invalid': v$.data.quantity.$error }"/>
      <div v-for="(item, index) in v$.data.quantity.$errors" :key="index"
        class="invalid-feedback">
        <span v-if="item.$message">{{ item.$message }}</span>
      </div>
    </div>
    <div class="col-sm-6">
        <label for="tag" class="form-label">Choose tag</label>
        <select v-model="data.tag" :class="{ 'is-invalid': v$.data.tag.$error }"
            name="tag" id="tag" class="form-select">
        <option disabled value="" >--choose tag--</option>
            <option v-for="(tag, sIndex) in tagOptions" :key="sIndex" 
            :value="tag.value">{{tag.label}}</option>
        </select>
        <div v-for="(item, index) in v$.data.tag.$errors"
            :key="index" class="text-danger">
            <span v-if="item.$message">{{ item.$message }}</span>
        </div>
    </div>
    <div class="col-12">
      <label class="form-label">Reason</label>
      <textarea v-model="data.reason" class="form-control"
      rows="4" placeholder="Write reason" name="reason"></textarea>
    </div> 
  </div>
  <div class="row mt-3 justify-content-end">
    <div class="col-12">
        <div class="d-flex mt-3 justify-content-end">
            <b-button variant="primary" 
            @click.prevent="editMode? update() : create()"
                type="button">{{editMode? 'Update':'Send Request'}}</b-button>
        </div>
    </div>
    </div>
</form>
</template>

<script>
import { required, email, maxValue, minLength, helpers } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import Multiselect from '@vueform/multiselect'
import IsLoading from '@/components/IsLoading.vue';

export default {
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    Multiselect,
    IsLoading,
  },
  props:{
    editItem:{
      type: Object,
      default: null
    },
    editMode:{
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
        isLoading: true,
        data: {  
            medicine_id: null,
            quantity: null,
            tag: "",
            reason: ""
        },
        mirrorData: null,
        medicineTypes: [],
        medicineOptions:[],
        tagOptions:[
            { value: "Anytime", label: "Anytime" },
            { value: "Urgent", label: "Urgent" }
        ],
    }
  },
  validations() {
    return {
      data:{
        medicine_id: {
          required: helpers.withMessage("Medicine is required", required),
        },
        quantity: {
          required: helpers.withMessage("Quantity is required", required),
        },
        tag: {
          required: helpers.withMessage("Tag is required", required),
        },
        reason: {
          required: helpers.withMessage("Your reason is required", required),
        },
      }
    }
  },
  computed:{
    selected(){
      return this.medicineOptions.find(i => i.id == this.data.medicine_id)
    },
  },
  methods: {
    create() {
      this.v$.$validate().then(result =>{
      if (!result){ this.alertError('Form not valid'); return;}
        let formData = new FormData();
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post('/medicine-requests/create', formData)
        .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
                this.$emit('addItem', response.data.data)
                this.resetForm();
            }
        })
      })
    },
    update(){
      this.v$.$validate().then(result =>{
        if (!result){ this.alertError('Form not valid'); return;}
        const formData = new FormData()
        formData.append('data', JSON.stringify(this.data))
        this.$store.dispatch("changeLoaderValue", true)
        this.$http.post(`/medicine-requests/${this.editItem.id}/update`,formData)
          .then((response) => {
            this.$store.dispatch("changeLoaderValue", false)
            if(response.data.success){
              this.$emit('updatedItem', response.data.data)
              this.resetForm()
            }
          })
      })
    },
    resetForm() {
      this.data = {...this.mirrorData}
      this.v$.$reset()
      this.$emit('closeMe')
    },
    getOptionData(){
      this.isLoading = true;
      this.$http.get(`/medicine-types`)
        .then((response) => {
        this.isLoading = false;
        if(response.data.success){
          this.medicineTypes = response.data.data
        }
      })
    },
    medicineTypeChange(type_id){
      this.medicineOptions = []
      this.data.medicine_id = null
      this.isLoading = true;
      this.$http.get(`/medicines?medicine_type_id=${type_id}`)
        .then((response) => {
        this.isLoading = false;
        if(response.data.success){
          this.medicineOptions = response.data.data
        }
      })
    }
  },
  created(){
    this.getOptionData()
  },
  mounted(){
    this.mirrorData = JSON.parse(JSON.stringify(this.data))
    if(this.editMode){
      this.data = this.$filters.patchUpdatables(this.data, this.editItem)
    }
  }
}
</script>
    
<style scoped>
.bg-error-danger {
    --bs-bg-opacity: 1;
    background-color: #ffccc1 !important;
}
</style>